<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.707723 0.000195408C0.586276 -0.00196921 0.464261 0.0248036 0.354212 0.0806273C0.134904 0.192161 0 0.402699 0 0.630771V15.3694C0 15.5975 0.13603 15.808 0.355233 15.9197C0.46107 15.9736 0.578758 16 0.695416 16C0.820622 16 0.94708 15.9696 1.05712 15.9079L14.3798 8.53848C14.5873 8.42398 14.7135 8.22028 14.7135 8.00006C14.7135 7.77972 14.5872 7.57614 14.3798 7.46164L1.05712 0.092168C0.94969 0.0329276 0.829042 0.00205323 0.707723 0V0.000195408ZM1.39083 1.75478L12.6824 8.00022L1.39083 14.2465V1.75478Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "IconPlay"
}
</script>

<style lang="scss" scoped>

</style>